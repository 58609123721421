import '../vendors/jquery.validate.min';

$(document).ready(function () {
  const $form = $(".subscribe_form");
  const $button = $form.find(".subscribe_form__btn");
  const $input = $form.find(".subscribe_form__input");
  const $error = $form.find(".subscribe_form__error");
  const $errorText = $form.find(".subscribe_form-error");
  const $success = $form.find(".subscribe_form-success");
  const $formInner = $form.find(".subscribe_form__group");

  $form.on("submit", function(e) {
    e.preventDefault()
  })

  $form.validate({
    errorClass: "error",
    validClass: "success",
    errorElement: "em",
    focusInvalid: true,
    rules: {
      email: {
        required: true,
        checkEmail: true,
        email: true
      },
    },
    messages: {
      email: {
        required: "Укажите email",
        checkEmail: "E-mail должен быть в формате name@domain.com",
        email: "E-mail должен быть в формате name@domain.com"
      },
    },
    invalidHandler: function (event, validator) {
      let errors = validator.numberOfInvalids();
      if (errors) {
        $errorText.show();
      }
    },
    submitHandler: function(form) {
      $errorText.hide();
      $("em.error").hide();
      $error.removeClass("active");
      const formData = new FormData(form);

      $.ajax({
        url: $form.attr("data-action"),
        method: 'POST',
        contentType: false,
        processData: false,
        data: formData,
        dataType: 'json',
        success: function (data) {
          if (data.success) {
            $success.addClass("active")
            $formInner.addClass("hidden")
          } else {
            $success.removeClass("active")
            $formInner.removeClass("hidden")
            $error.html(data.text).addClass("active")
          }
        },
        error: function (data) {
          console.log(data);
          $success.removeClass("active")
          $formInner.removeClass("hidden")
          $error.text(data.text).addClass("active")
        }
      });
    },
    highlight: function(element, errorClass, validClass) {
      $(element).addClass(errorClass).removeClass(validClass);
    },
    unhighlight: function(element, errorClass, validClass) {
      $(element).removeClass(errorClass).addClass(validClass);
    },
    errorPlacement: function(error,element) {
      return true;
    }
  });


  jQuery.validator.addMethod("checkEmail", function(value, element) {
    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(value);
  });

})

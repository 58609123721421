$(document).ready(function () {
  const ticketsBtn = $('.js-buy-tickets');
  const modal = $('.js-modal-buy-tickets')[0];
  const close = $('.js-modal-close');

  function handleModalClick(e) {
    modal.classList.toggle('show');
  }

  ticketsBtn.on('click', handleModalClick);
  close.on('click', handleModalClick);
})

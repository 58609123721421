import owlCarousel from 'owl.carousel'
import { Fancybox } from "@fancyapps/ui";
import Masonry from 'masonry-layout';
import './components/dropdown-list';
import './components/collapse-slider';
import './components/modal';
import './components/poor-vision-toggle';
import './components/burger';
import './components/promo-list';
import './components/search';
import './components/searchHeader';
import './components/subscribe-form';
import './components/ya-map';
// import './components/video-player';
import '../../node_modules/pgwslider/pgwslider';
import '../../node_modules/slider-pro/dist/js/jquery.sliderPro';
import { initMediaSlider } from './components/media-slider';
import { getCookie, setCookie, deleteCookie } from './components/cookie-utils'
import History from './components/history';
import CollapseImageSlider from './components/collapse-image-slider';
import CollapseVideoSlider from './components/collapse-video-slider';

require('select2')

$(document).ready(function () {

  function initEventsSlider() {
    const eventsSlider = $(".events-list").owlCarousel({
      dots: false,
      responsive: {
        0: {
          items: 2
        },
        768: {
          items: 4
        }
      }
    });

    $('.events__next').on('click', function (event) {
      eventsSlider.trigger('next.owl.carousel');
    });
    $('.events__prev').on('click', function (event) {
      eventsSlider.trigger('prev.owl.carousel');
    });
  }

  function initHeroSlider() {
    const heroSlider = $(".hero-list").owlCarousel({
      dots: false,
      loop: true,
      autoplay: true,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1
        },
      }
    });

    $('.hero__next').on('click', function (event) {
      heroSlider.trigger('next.owl.carousel');
    });
    $('.hero__prev').on('click', function (event) {
      heroSlider.trigger('prev.owl.carousel');
    });
  }

  function initCookies() {
    if (getCookie('cookies')) {
      return
    }

    const cookieBlock = $('.cookies')
    cookieBlock.show()
    const link = $('.cookies__link')

    link.on('click', function (event) {
      event.preventDefault();
      cookieBlock.hide()
      const setting = {
        expires: 31104000 // год в секундах
      }
      setCookie('cookies', 'Y', setting)
    });
  }

  const newsMasonry = () => {
    let grid = document.querySelector('.news_grid');

    if (!grid) {
      return null
    }

    let msnry = new Masonry(grid, {
      itemSelector: '.news_grid_item',
      columnWidth: '.news_grid_sizer',
      percentPosition: true,
    });
  }

  const playListMansory = () => {
    let playsGrid = document.querySelector('.plays-list__grid');

    if (!playsGrid) {
      return null
    }

    let msnry = new Masonry(playsGrid, {
      itemSelector: '.plays-list__item',
      columnWidth: '.plays-list__sizer',
      percentPosition: true,
    });
  }

  function initPersonSlider() {
    const elements = [$(".media-slider--person-head"), $(".media-slider--person-video")]
    elements.forEach(element => {
      const counter = !$(element).hasClass('media-slider--person-head');
      initMediaSlider(element, counter)
    })

    // $('.media-slider--person-photo').each((i,el) => {
    //   new CollapseImageSlider(el);
    // })
  }

  function initCollapseSlider() {
    $('.collapse-slider-photo').each((i,el) => {
      new CollapseImageSlider(el);
    })
  }

  function initCollapseVideoSlider() {
    $('.collapse-slider-video').each((i,el) => {
      new CollapseVideoSlider(el);
    })
  }

  function initVideoSliders() {
    const elements = [$(".media-slider--play-video")]
    elements.forEach(element => initMediaSlider(element))
  }

  const selectInit = () => {

    if ($('.select--big_mod').length) {

      $('.select--big_mod').each((i, select) => {
        $(select).select2({
          minimumResultsForSearch: Infinity,
          dropdownCssClass: "dropdown--big_mod",
          // allowClear: true,
          placeholder: $(select).data('name')
        })
      })

      /* $('.select--big_mod').on('select2:select', (evt) => {
        disableOptions('.select--big_mod')
      }) */
    }
  }

  /* const disableOptions = (selector) => {
    const selects = $(selector)
    const disabledValues = ['-1']
    selects.each(function (i, el) {
      this.value && disabledValues.push(this.value)
    })
    selects.each((i, el) => {
      const options = $(el).find('option')
      options.each((optionIndex, option) => {
        if (disabledValues.includes($(option).val())) {
          $(option).attr({ disabled: $(el).val() !== $(option).val() })
        } else {
          $(option).attr({ disabled: false })
        }
      })
    })
  } */

  const accordionInit = () => {
    if ($('.accordion__items').length) {
      $(".accordion__items").on("click", ".accordion__head", function () {
        $(this).toggleClass("active").next().slideToggle();

        $(".accordion__content").not($(this).next()).slideUp(300);

        $(".accordion__head").not($(this)).removeClass("active");
      });
    }

    if ($('.accordion__child_items').length) {
      $(".accordion__child_items").on("click", ".accordion__child_head", function () {
        $(this).toggleClass("active").next().slideToggle();

        $(".accordion__child_content").not($(this).next()).slideUp(300);

        $(".accordion__child_head").not($(this)).removeClass("active");
      });
    }
  }

  const initScrollbar = () => {
    const subMenu = $('.header-menu__list');
    const maxHeight = (70 - 4.8) * (10 / 1920 * window.innerWidth);

    subMenu.each((i, list) => {
      if ($(list).height() === maxHeight) {
        $(list).addClass('scrollbar--active')
      }
    })

  }

  const initButtonUp = () => {
    const $btn = $('.js-button-up');

    $btn.on('click', () => {
      $('body,html').animate({ scrollTop: 0 }, 500);
    })

    $(window).on('scroll', () => {
      $(window).scrollTop() > 200 ? $btn.fadeIn() : $btn.fadeOut();
    })
  }

  const initSelectCheckbox = () => {
    $('.js-select-checkbox').find('input[type="checkbox"]').on('change', () => {
      $('.js-select-checkbox').toggleClass('active')
    })
  }

  function init() {
    // слайдер на главной Ближайшие события
    initEventsSlider();

    // слайдер на главной
    initHeroSlider();

    initPersonSlider();
    initCollapseSlider();
    initCollapseVideoSlider();
    initVideoSliders();

    initCookies();
    newsMasonry();
    playListMansory();
    selectInit();
    initSelectCheckbox();
    accordionInit();

    initScrollbar();
    initButtonUp();
    const history = new History();
  }

  init()
});

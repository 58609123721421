export default class CollapseVideoSlider {
  constructor(el = '.collapse-slider-video') {
    this.$el = $(el);
    this.$sliderHead = this.$el.find('.collapse-slider-video__head');
    this.$sliderContent = this.$el.find('.collapse-slider-video__info-wrap');

    this.init();
  }

  init() {
    const {$el} = this;
    const videoSlider = $el.find('.video-slider').sliderPro({
      width: 'calc(100% - 500px)',
      height: 500,
      arrows: true,
      buttons: false,
      autoplay: false,
      thumbnailsPosition: 'right',
      thumbnailWidth: 500,
      thumbnailHeight: 200,
      autoSlideSize: true,
      fadeArrows: false,
      centerImage: true,
      autoHeight: true,
      autoSlideSize: true,
      breakpoints: {
        1280: {
          width: 'calc(100% - 300px)',
          height: 400,
          thumbnailsPosition: 'right',
          thumbnailWidth: 300,
          thumbnailHeight: 140,
        },
        1024: {
          width: '100%',
          height: 300,
          thumbnailsPosition: 'bottom',
          thumbnailWidth: 300,
          thumbnailHeight: 140,
        }
      },
    });

    this.$sliderHead.on('click', function() {
      $(this).toggleClass("active").next().slideToggle().addClass('loading');
      const isOpen = $(this).hasClass('active');
      
      setTimeout(() => {
        $(this).next().removeClass('loading');
        if (isOpen) {
          videoSlider.resize();
        }
      }, 500);
    });
  }
}
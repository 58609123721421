import { throttle } from '../utils';

$(document).ready(function () {
  const slider = $('.collapse-slider')

  if (slider.length) {
    slider.on("click", ".collapse-slider__head", function () {
      $(this).toggleClass("active").next().slideToggle().addClass('loading');

      setTimeout(() => {
        $(this).next().removeClass('loading')
      }, 500)

      const afisha = $('.afisha-card');
      const afishaImg = afisha.find('.afisha-card__image').find('img');

      function handleImageHeight() {
        afishaImg.css({ maxHeight: afisha.height() });
      }

      handleImageHeight();
      $(window).resize(throttle(handleImageHeight, 1100));
    });
  }
})

$(document).ready(function () {
  const slider = $('.pushkin-card__header');

  if (slider.length) {
    slider.on("click", function() {
      $(this).toggleClass("active").find('.pushkin-card__info').slideToggle();
    })
  }
})

import { throttle } from '../utils';

$(document).ready(function () {
  const promoList = $('.promo-list');

  function initCarousel() {
    promoList.addClass('owl-carousel');

    promoList.owlCarousel({
      dots: false,
      responsive: {
        0: {
          items: 1
        },
      }
    });

    $('.promo__next').on('click', function (event) {
      promoList.trigger('next.owl.carousel');
    });
    $('.promo__prev').on('click', function (event) {
      promoList.trigger('prev.owl.carousel');
    });
  }

  function onResizeHandle() {
    if (window.innerWidth < 540) {
      if (!promoList.hasClass('owl-carousel')) {
        initCarousel();
      }
    } else {
      if (promoList.hasClass('owl-carousel')) {
        promoList.removeClass('owl-carousel');
        promoList.trigger('destroy.owl.carousel');
      }
    }
  }

  if (window.innerWidth < 540) {
    initCarousel();
  }

  if (promoList.length) {
    $(window).on('resize', throttle((onResizeHandle), 500))
  }
})

$(document).ready(function () {
  $('.header__burger').on('click', function () {
    if ($('.header__top-search').hasClass('active')) {
      $('.header__top-search').removeClass('active')
    }
    if ($('.header__nav').length) {
      $('.header__burger, .header__nav').toggleClass('active');
    } else if ($('.header__nav-donate').length) {
      $('.header-donate, .header__burger, .header__nav-donate').toggleClass('active');
    }
    $('body').toggleClass('scroll-off');
  })
})

export function initMediaSlider(element, counter = true) {
  const counterEl = element.find('.media-slider__counter');
  const sliderImages = element.find('.media-slider__item img');
  const slider = element.find('.owl-carousel').owlCarousel({
    dots: false,
    onInitialized: init,
    onTranslated:  counter ? handleCounter : null,
    responsive: {
      0: {
        items: 1
      },
    }
  });

  element.find('.media-slider__next').on('click', function (event) {
    slider.trigger('next.owl.carousel');
  });
  element.find('.media-slider__prev').on('click', function (event) {
    slider.trigger('prev.owl.carousel');
  });

  slider.on('drag.owl.carousel', () => {
    if (sliderImages.length) {
      disableImagesClick(sliderImages);
    }
  });

  slider.on('dragged.owl.carousel', () => {
    if (sliderImages.length) {
      setTimeout(() => {
        enableImagesClick(sliderImages);
      }, 400);
    }
  });


  function init(event) {
    if (event.item.count <= 1) {
      element.find('.media-slider__next').hide();
      element.find('.media-slider__prev').hide();
    }
    if (counterEl.length) {
      counterEl.text(`${event.item.index + 1}/${event.item.count}`)
    }

    if (sliderImages.length) {
      enableImagesClick(sliderImages);
    }
  }

  function handleCounter(event) {
    counterEl.text(`${event.item.index + 1}/${event.item.count}`)
  }

  function enableImagesClick(images) {
    images.each((i,el) => {
      $(el).on('click', handleImageClick);
    })
  }

  function disableImagesClick(images) {
    images.each((i,el) => {
      $(el).off('click', handleImageClick);
    })
  }

  function handleImageClick() {
    const src = $(this).attr('src');
    $('<div>').css({
      background: 'RGBA(0,0,0,.5) url('+src+') no-repeat center',
      backgroundSize: 'contain',
      width:'100%', height:'100%',
      position:'fixed',
      zIndex:'10000',
      top:'0', left:'0',
      cursor: 'zoom-out'
    }).on('click', function(){
      $(this).remove();
    }).appendTo('body');
  }
}

$(document).ready(function () {
  const initMap = () => {
    ymaps.ready(function () {
      const mapEl = document.querySelector('.contacts__map-container');
      const map = new ymaps.Map(mapEl, {
        center: [55.759121, 37.617451],
        zoom: 15,
      });


      const myGeoObject = new ymaps.GeoObject({
        geometry: {
          type: "Point",
          coordinates: [55.759121, 37.617451]
        },
      })

      if (map) {
        ymaps.modules.require(['Placemark'], function (Placemark) {
          map.geoObjects.add(myGeoObject).add(
            new Placemark([55.759121, 37.617451], {}, { preset: 'islands#icon', iconColor: '#C50735' }))
        });
        map.behaviors.disable('scrollZoom');
      }

     /*  map.controls.add('routeButtonControl');

      const routePanel = map.controls.get('routeButtonControl').routePanel;
      const routeButton = new ymaps.control.Button('Построить маршрут');

      routeButton.events.add('click', (evt) => {
        ymaps.geolocation.get({
          provider: 'auto',
        }).then((result) => {
          routePanel.state.set({
            fromEnabled: false,
            from: result.geoObjects.position,
            to: '55.759121, 37.617451',
            type: 'auto'
          });
          map.controls.get('routeButtonControl').state.set('expanded', true);
        })
      })

      map.controls.add(routeButton, {
        position: {
          top: 45,
          right: 10
        },
        maxWidth: 300
      }) */
    });
  }
  initMap()
})

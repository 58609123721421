import { throttle } from '../utils';

$(document).ready(function () {
  const menuItems = $('.menu-select__item-wrap');
  const search = $('.menu-select__search');
  const deleteIcons = menuItems.find('.menu-select__item-delete');

  function handleDropdownClick(e) {
    $(this).toggleClass('active');
    $(this).find('.menu-select__dropdown-wrap').toggleClass("active").slideToggle(300);

    const value = $(this).find('.menu-select__item-value');
    const deleteIcon = $(this).find('.menu-select__item-delete');

    if (e.target.tagName === 'LI') {
      value.text(e.target.innerText);
      value.addClass('active');
      deleteIcon.addClass('changed');
    }
  }

  function handleResetValue(e) {
    e.stopPropagation();
    const value = $(this).siblings();

    if (value.data("default")) {
      $(this).removeClass("changed");
      value.text(value.data('default'));
    }
  }

  menuItems.on('click', handleDropdownClick)
  search.on("click", function () {
    $(this).toggleClass("active").next().toggleClass('active');
  });
  deleteIcons.on("click", handleResetValue);
})


$(document).ready(function () {
  const afisha = $('.afisha-select');
  const afishaItem = afisha.find('.afisha-select__item');

  function handleDropdownClick(e) {
    $(e.currentTarget).parent().toggleClass('active');
    $(e.currentTarget).parent().find('.afisha-select__dropdown-wrap').toggleClass("active").slideToggle(200);
  }

  function handleCloseAfisha() {
    afishaItem.parent().removeClass('active');
    afishaItem.parent().find('.afisha-select__dropdown-wrap').removeClass("active").slideUp(200);
  }

  afishaItem.on('click', handleDropdownClick)
  $(window).resize(throttle(handleCloseAfisha, 1000));
})


$(document).ready(function () {
  const afisha = $('.afisha-card');
  const afishaImg = afisha.find('.afisha-card__image').find('img');

  function handleImageHeight() {
    afishaImg.css({ maxHeight: afisha.height() });
  }

  handleImageHeight();
  $(window).resize(throttle(handleImageHeight, 1100));
})

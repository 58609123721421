const sliderOptions = {
  autoSlide: false,
  displayControls: true,
  maxHeight: 860,
  listPosition: 'right',
  transitionEffect: 'sliding',
  // verticalCentering: true,
  // adaptiveHeight:true,
};

export default class PgwSlider {
  constructor(el) {
    this.$el = $(el);
    this.$sliderEl = this.$el.find('.pgwSlider');
    this.$slider = null;

    this.handleImageClick = this.handleImageClick.bind(this);
    this.addImageClickListener = this.addImageClickListener.bind(this);
    this.removeImageClickListener = this.removeImageClickListener.bind(this);

    this.init();
  }

  init() {
    const {addImageClickListener, removeImageClickListener} = this;
    this.$slider = this.$sliderEl.pgwSlider({
      ...sliderOptions,
      beforeSlide: (index) => {
        removeImageClickListener(index);
      },
      afterSlide: (index) => {
        addImageClickListener(index);
      }
    });
  }

  destroy() {
    this.$slider.destroy(true);
  }

  reload() {
    const {addImageClickListener, removeImageClickListener} = this;

    if (this.$slider && this.$slider.length) {
      this.$slider.reload({
        ...sliderOptions,
        beforeSlide: (index) => {
          removeImageClickListener(index);
        },
        afterSlide: (index) => {
          addImageClickListener(index);
        }
      });
    }
  }

  addImageClickListener(slideIndex) {
    const currentSlideImg = this.$el.find(`.ps-current .elt_${slideIndex} img`);
    currentSlideImg.on('click', this.handleImageClick);
  }
  
  removeImageClickListener(slideIndex) {
    const currentSlideImg = this.$el.find(`.ps-current .elt_${slideIndex} img`);
    currentSlideImg.off('click', this.handleImageClick);
  }

  handleImageClick({target}) {
    const src = $(target).attr('src');
    $('<div>').css({
      background: 'RGBA(0,0,0,.5) url('+src+') no-repeat center',
      backgroundSize: 'contain',
      width:'100%', height:'100%',
      position:'fixed',
      zIndex:'10000',
      top:'0', left:'0',
      cursor: 'zoom-out'
    }).on('click', function(){
      $(this).remove();
    }).appendTo('body');
  }
}
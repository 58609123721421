import PgwSlider from './pgwSlider';

export default class CollapseImageSlider {
  constructor(el = '.collapse-slider-photo') {
    this.$el = $(el);
    this.$sliderHead = this.$el.find('.collapse-slider-photo__head');
    this.$sliderContent = this.$el.find('.collapse-slider-photo__info-wrap');
    this.imageSliderClass = '.image-slider';

    this.init();
  }

  init() {
    const imgSlider = new PgwSlider(this.imageSliderClass);

    this.$sliderHead.on('click', function() {
      $(this).toggleClass("active").next().slideToggle().addClass('loading');
      const isOpen = $(this).hasClass('active');

      setTimeout(() => {
        $(this).next().removeClass('loading');
        if (isOpen) {
          if (imgSlider) {
            imgSlider.reload();
          }
        }
      }, 500);
    })
  }
}
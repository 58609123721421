$(document).ready(function () {
  const searchIcon = $('.header__top-icon-search');
  const search = $('.header__top-search');
  const socials = $('.header__top-socials');
  const iconPoorVision = $('.header__top-icon-poor-vision');
  const headerText = $('.header__search-hidden');

  const toggleHeader = () => {
    socials.toggle();
    iconPoorVision.toggle();
    headerText.toggle();
  }

  if (search) {
    searchIcon.on("click", function (e) {
      if (search.hasClass('active')) {
        setTimeout(() => {
          toggleHeader();
        }, 300)
      } else {
        toggleHeader();
      }

      search.toggleClass('active');
    });
  }
})

window.onload = () => {
  const input = $('.gsc-input');

  input.attr('placeholder', 'Введите запрос');
}

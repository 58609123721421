import { setCookie, deleteCookie, getCookie } from './cookie-utils';

$(document).ready(function () {
  const POOR_VISION_KEY = 'ramtPoorVisionCookie';
  const cookiesSettings = {
    expires: 31104000
  }

  if (getCookie(POOR_VISION_KEY)) {
    poorVisionOn();
  }

  const icon = $('.header__top-icon-poor-vision');
  const sizes = $('.header__poor-vision-font');
  const offToggle = $('.header__poor-vision-off');

  function poorVisionOn(e) {
    document.documentElement.classList.add('poor-vision');
    document.documentElement.dataset.fontSize = 'size-sm';

    if (!getCookie(POOR_VISION_KEY)) {
      setCookie(POOR_VISION_KEY, 'Y', cookiesSettings);
    }
  }

  function poorVisionOff(e) {
    document.documentElement.classList.remove('poor-vision');

    deleteCookie(POOR_VISION_KEY)
  }

  function handleSizeClick(e) {
    const size = e.target.dataset.fontSize;
    if (size) {
      document.documentElement.dataset.fontSize = size;
    }
  }

  icon.on('click', poorVisionOn)
  sizes.on('click', handleSizeClick)
  offToggle.on('click', poorVisionOff)
})

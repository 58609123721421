export default class History {
  constructor() {
    this.$cardsParent = $('.js-history-cards');
    this.$card =  this.$cardsParent.find('.js-history-card');

    this.init();
  }

  init() {
    if (this.$card.length) {
      this.$card.on('click', this.handleCardClick.bind(this));
    }
  }

  handleCardClick(e) {
    const $target = $(e.currentTarget);
    const sectionId = $target.data('historySection');
    const $card = $(`[data-section=${sectionId}]`);

    this.handleCloseCards();

    $('html, body').animate({scrollTop: $card.offset().top}, 1000);

    $card.find('.accordion__head').toggleClass("active").next().slideDown(300);
  }

  handleCloseCards() {
    $('.accordion__head').removeClass('active').next().slideUp();
    $('.accordion__child_head').removeClass('active').next().slideUp();
  }
}
